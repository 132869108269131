import { Link, Paragraph } from '@purpurds/purpur';
import { useTranslation } from 'react-i18next';
import './WebsitesNavLink.scss';

import NavItemLink from '../NavItemLink/NavItemLink';

import PurpurIcon from 'components/purpur/Icon/Icon';

type Props = {
  isMobile?: boolean;
};

const WebsitesNavLink = ({ isMobile = false }: Props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const linkUrl = `/${currentLanguage}/${t('link_articles')}/websites`;
  const linkText = t('link_websites');

  return (
    <div
      className={`websites-nav-link ${
        isMobile ? 'websites-nav-link--mobile' : 'websites-nav-link--desktop'
      }`}
      data-testid="websites-nav-link"
    >
      {isMobile ? (
        <Link
          variant="navigation"
          href={linkUrl}
          className="websites-nav-link__link"
          aria-label="link to article about Telia branded websites"
          disableVisited
        >
          <PurpurIcon iconName="internet" size="sm" className="websites-nav-link__icon" />
          <Paragraph variant="paragraph-100-medium" className="websites-nav-link__text">
            {linkText}
          </Paragraph>
        </Link>
      ) : (
        <NavItemLink url={linkUrl} title={linkText} icon="internet" />
      )}
      <div
        className={`websites-nav-link__divider ${
          isMobile ? '' : 'websites-nav-link--desktop__divider'
        }`}
        data-testid="websites-nav-link__divider"
      />
    </div>
  );
};

export default WebsitesNavLink;
