import { TeliaFooter, TeliaFooterNavigation } from '@teliads/components/react';
import { useTranslation } from 'react-i18next';

//@ts-ignore
import { ReactComponent as TeliaFooterIcon } from './TeliaFooterIcon.svg';

import './TheFooter.scss';

const GetFooterLinks = () => {
  const { t, i18n } = useTranslation();

  return [
    {
      header: t('footer.contact_us'),
      links: [
        {
          href: `/${i18n.language}/${t('link_articles')}/${t(
            'navigation.markets_and_brands.slug'
          )}`,
          name: t('footer.our_brands'),
        },
        {
          href: `/${i18n.language}/${t('link_articles')}/${t('navigation.press_and_media.slug')}`,
          name: t('navigation.press_and_media.title'),
        },
        {
          href: `/${i18n.language}/${t('navigation.investors.slug')}`,
          name: t('navigation.investor_relations.title'),
        },
      ],
    },
    {
      header: t('footer.quick_links'),
      links: [
        {
          href: `/${i18n.language}/${t('link_articles')}/${t('navigation.privacy.slug')}`,
          name: t('navigation.privacy.title'),
        },
        {
          href: `/${i18n.language}/${t('link_articles')}/${t(
            'footer.telia_copmany_in_brief.slug'
          )}`,
          name: t('footer.telia_copmany_in_brief.title'),
        },
        {
          href: `/${i18n.language}/${t('link_careers')}/${t('navigation.open_positions.slug')}`,
          name: t('open_positions'),
        },
        {
          href: `/${i18n.language}/${t('navigation.reports_and_presentations.slug')}`,
          name: t('footer.financial_reports_and_presentations'),
        },
      ],
    },
    {
      header: t('footer.about_this_site'),
      links: [
        {
          href: `/${i18n.language}/${t('link_articles')}/${t('footer.terms_and_conditions.slug')}`,
          name: t('footer.terms_and_conditions.title'),
        },
      ],
    },
  ];
};

function cookieSettingsScriptInit() {
  // @ts-ignore
  window.OneTrust.ToggleInfoDisplay();
}

export const TheFooter = () => {
  const { t, i18n } = useTranslation();
  const cookieListUrl = `/${i18n.language}/${t('link_articles')}/${t('footer.cookies.slug')}`;
  // @ts-ignore
  return (
    <div className="the-footer">
      <TeliaFooter>
        <a slot="logo" href={`/${i18n.language}`} aria-label={t('footer.telia_icon')}>
          <TeliaFooterIcon className="the-footer__logo" />
        </a>

        <TeliaFooterNavigation slot="navigation" links={JSON.stringify(GetFooterLinks())} />
        <section slot="legal" className="legalrow">
          <p className="legalrow__legal-text">
            &copy; Telia Company AB 556103-4249 Stjärntorget 1 Solna
          </p>
          <div className="consent-list">
            <li className="consent-list__item">
              <button
                className="consent-list__cookie-settings"
                onClick={cookieSettingsScriptInit}
                aria-label={t('footer.cookies_settings')}
              >
                {t('footer.cookies_settings')}
              </button>
            </li>
            <li className="consent-list__item">
              <a className="consent-list__item-value" href={cookieListUrl}>
                {t('footer.cookies.title')}
              </a>
            </li>
          </div>
        </section>
      </TeliaFooter>
    </div>
  );
};
